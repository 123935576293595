import React, { useEffect, useRef, useState } from 'react';
import CalenderDropdown from '../../../../components/DropDowns/CalenderDropdown';
import Pagination from './components/Pagination';
import Download from './components/Download';
import FilterModal from './components/FilterModel';
import { TableRow } from '../types/types';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import PartnerAPI from '../../../../api/partnerApi';
import { toast } from '../../../../components/Toast/CustomToast';
import { useSearchParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import TitleTooltip from '../../../../components/BarData/TitleTooltip';
import { BpCheckedIcon, BpIcon } from '../../../../components/layout/layout-partner/LayoutPartner';
import FilterDrawer from './components/FilterDrawer';
import OpenCSRModel from '../../../common/openModel/OpenCSRModel';
import OpenModel from '../../../common/openModel/OpenVendorModel';
import InvoiceAmount from './components/InvoiceAmount';
import InvoiceStatus from './components/InvoiceStatus';
import Heading from './components/Heading';
import { getMiles } from '../../../csr/primaryComponents/TillMiles';
import Tootltip from '../../../../components/BarData/Tootltip';
import { SearchInvoice, SearchReport } from './components/Search';

const initialView = {
  viewChecklist: false,
  submitProof: false,
};
let debounceTimeout: any;
const InvoiceTable: React.FC = () => {
  const [showModel, setShowModel] = React.useState<any>({ open: false, data: {} });
  const dispatch = useDispatch();
  const [showGrossPopover, setShowGrossPopover] = useState(false);
  const [showVendorPopover, setShowVendorPopover] = useState(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { setMultiInvoiceItems, setLayoutContents } = bindActionCreators(actionCreators, dispatch);
  const { multiSelectInvoiceItems, user, invoiceView, customizeView }: any = useSelector(
    (state: RootState) => state.userState
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [openDownload, setDownloadOpen] = useState(false);
  const [downloadType, setDownloadType] = useState('');
  const [rawData, setRawData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [revenueModel, setRevenueModel] = useState<any>([]);
  const [revenueType, setRevenueType] = useState('last_7_days');
  const itemsPerPage = 20;
  const loader = HandleLoad();
  const [urlParams, setSearchParams]: any = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(0);
  const isInvoicePage = window.location.href.includes('invoice');
  const isVendorRequestReport = window.location.href.includes('vendorRequestReport');

  const [filterType, setFilterType] = useState(
    `${isInvoicePage || isVendorRequestReport ? `last_7_days` : ''}`
  );
  const inputRef: any = useRef<HTMLDivElement>(null);
  const handleSearch = (text: any) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      let lowercaseSearchText: string = '';
      if (typeof text === 'string') {
        lowercaseSearchText = text.toLowerCase();
      }
      if (lowercaseSearchText === '') {
        urlParams.delete('search');
        if (urlParams.get('date_type') !== 'all') {
          urlParams.delete('date_type');
        }
        urlParams.set('page_number', '1');
        setSearchParams(urlParams);
      } else {
        urlParams.set('search', text);
        urlParams.set('page_number', '1');
        setSearchParams(urlParams);
      }
    }, 500);
  };

  useEffect(() => {
    setLayoutContents({
      search: isVendorRequestReport ? (
        <SearchReport inputRef={inputRef} handleSearch={handleSearch} />
      ) : (
        <SearchInvoice inputRef={inputRef} handleSearch={handleSearch} />
      ),
    });
  }, [inputRef, urlParams, isVendorRequestReport]);
  const fetchPartnerInvoiceData = () => {
    loader(true);
    if (isVendorRequestReport) {
      PartnerAPI.getVendorRequestReport({
        params: getQueryInvoicePartner(urlParams),
      }).then((res: any) => {
        if (res.status === 200) {
          const resultData: any = res.data.data;
          setTotalItems(res.data.data.total_records);
          setRawData(resultData.requests);
          setRevenueModel(resultData);
          localStorage.setItem('partnerId', resultData?.requests?.[0]?.partner?.id);
        } else {
          setTotalItems(0);
          setRawData([]);
          setRevenueModel([]);
          toast.error('Something Went wrong');
        }
        loader(false);
      });
    } else {
      PartnerAPI.getAllInvoiceDetails({
        params: getQueryInvoicePartner(urlParams),
      }).then((res: any) => {
        if (res.status === 200) {
          const resultData: any = res.data.data;
          setTotalItems(res.data.data.total_records);
          setRawData(resultData.requests);
          setRevenueModel(resultData);
          localStorage.setItem('partnerId', resultData?.requests?.[0]?.partner?.id);
        } else {
          setTotalItems(0);
          setRawData([]);
          setRevenueModel([]);
          toast.error('Something Went wrong');
        }
        loader(false);
      });
    }
  };
  useEffect(() => {
    urlParams.delete('date_type');
    urlParams.delete('start_date');
    urlParams.delete('end_date');
    urlParams.delete('page_number');
    urlParams.delete('search');
    setMultiInvoiceItems([]);
    setSearchParams(urlParams);
    setRevenueType('last_7_days');
    setFilterType('last_7_days');
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterType === 'last_7_days') {
        if (urlParams.get('start_date')) {
          fetchPartnerInvoiceData();
        }
      } else if (
        (urlParams.get('start_date') && urlParams.get('page_number')) ||
        urlParams.get('date_type') === 'all'
      ) {
        fetchPartnerInvoiceData();
      }
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [urlParams]);
  useEffect(() => {
    if (urlParams.get('start_date') && !urlParams.get('page_number')) {
      fetchPartnerInvoiceData();
    }
  }, [urlParams]);

  useEffect(() => {
    if (
      count === 0 &&
      (urlParams.get('vendor_ids') ||
        urlParams.get('technician_ids') ||
        urlParams.get('csr_ids') ||
        urlParams.get('invoice_status') ||
        urlParams.get('services') ||
        urlParams.get('partner_ids') ||
        urlParams.get('station_ids') ||
        urlParams.get('dsp_ids'))
    ) {
      urlParams.delete('vendor_ids');
      urlParams.delete('technician_ids');
      urlParams.delete('csr_ids');
      urlParams.delete('invoice_status');
      urlParams.delete('services');
      urlParams.delete('partner_ids');
      urlParams.delete('station_ids');
      urlParams.delete('dsp_ids');

      setSearchParams(urlParams);

      if (
        filterType === 'last_7_days' &&
        (urlParams.get('start_date') || urlParams.get('date_type') === 'all')
      ) {
        setFilterType('last_7_days');
      }
    }
  }, [urlParams]);
  useEffect(() => {
    urlParams.set('page_number', currentPage.toString());
    setSearchParams(urlParams);
  }, [currentPage]);
  useEffect(() => {
    setRevenueType('last_7_days');
    setFilterType('last_7_days');
    setCount(0);
  }, [isVendorRequestReport]);
  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  const finalData = rawData.map((item: any) => {
    return {
      id: item.preventive_request_id,
      title: `Request-${item.preventive_request_id}`,
      preventive_request_id: item.preventive_request_id,
      ...item,
    };
  });
  const getDate = (item: any) => {
    if (isVendorRequestReport) {
      if (item?.date || item?.created_at) {
        return `${formatDate(item?.date || item?.created_at)}`;
      } else return 'N/A';
    } else {
      if (item?.log_date) {
        return `${formatDate(item?.log_date)}`;
      } else return 'N/A';
    }
  };
  useEffect(() => {
    const data1: TableRow[] = rawData.map((item: any) => {
      return {
        date: getDate(item),
        invoice_id: `${item?.invoice?.id}`,
        request_id: `${item?.preventive_request_id}`,
        csr: `${item?.csr.name !== undefined ? item?.csr.name : 'N/A'}`,
        vendor: `${item?.vendor?.name !== undefined ? item?.vendor?.name : 'N/A'}`,
        technician: `${item?.technician?.name !== undefined ? item?.technician?.name : 'N/A'}`,
        service_items: `${
          (item?.invoice?.services?.length || item?.services?.length) > 0
            ? (item?.invoice?.services || item?.services)
                ?.map((service: any, index: any) => `${service?.name}`)
                ?.join(',\n')
            : 'N/A'
        }`,
        amount: `${
          item?.invoice?.total_amount === 0 || item?.invoice?.total_amount === null
            ? 'N/A'
            : parseFloat(item?.invoice?.total_amount || 0).toFixed(2)
        }`,
        status: `${
          item?.invoice_status || item?.request_status !== undefined
            ? item?.invoice_status || item?.request_status
            : 'N/A'
        }`,
        request_substatus: `${item?.sub_request_status || 'N/A'}`,
        station_code: `${item?.dsp?.station_code !== undefined ? item?.dsp?.station_code : 'N/A'}`,
        dsp_shortcode: `${
          item?.dsp?.dsp_short_code !== undefined ? item?.dsp?.dsp_short_code : 'N/A'
        }`,
        van_name: `${item?.vehicle?.name !== undefined ? item?.vehicle?.name : 'N/A'}`,
        van_type: `${item?.vehicle?.type !== undefined ? item?.vehicle?.type : 'N/A'}`,
        vin_number: `${item?.vehicle?.vin !== undefined ? item?.vehicle?.vin : 'N/A'}`,
        dsp_address: `${item?.dsp?.address}`,
        dsp_name: `${item?.dsp?.name}`,
        partner: `${item?.partner?.name !== undefined ? item?.partner?.name : 'N/A'}`,
        miles: `${item?.miles !== undefined ? item?.miles : 'N/A'}`,
        current_miles: `${item?.curr_miles !== undefined ? item?.curr_miles : 'N/A'}`,
        miles_till_inspection: getMiles(item) || 'N/A',
        attempt: item?.attempt || null,
        service_completion_date: item?.service_completed_date
          ? `${formatDate(item?.service_completed_date)}`
          : 'N/A',
        revenue:
          item?.request_status === 'payment_confirmed' ? `$ ${item?.revenue?.total_amount}` : 'N/A',
        sub_total_revenue:
          item?.request_status === 'payment_confirmed'
            ? `$ ${item?.revenue?.subtotal_amount}`
            : 'N/A',
        tax_amount_revenue:
          item?.request_status === 'payment_confirmed' ? `$ ${item?.revenue?.tax_amount}` : 'N/A',
      };
    });
    setData(data1);
  }, [rawData, isVendorRequestReport]);

  const handleSelectAll = () => {
    if (allChecked) {
      setMultiInvoiceItems([]);
      if (urlParams.get('start_date') || !urlParams.get('page_number')) {
        urlParams.set('page_number', '1');
        setSearchParams(urlParams);
      }
    } else {
      setMultiInvoiceItems([...data]);
    }
    setAllChecked(!allChecked);
  };
  useEffect(() => {
    if (allChecked === true && multiSelectInvoiceItems.length !== data.length) {
      setMultiInvoiceItems([...data]);
    }
  }, [allChecked, data]);
  useEffect(() => {
    if (multiSelectInvoiceItems.length > 0 && multiSelectInvoiceItems.length === data.length) {
      urlParams.delete('page_number');
      setSearchParams(urlParams);
      setAllChecked(true);
    } else {
      setAllChecked(false);
      if (urlParams.get('data_type') && urlParams.get('start_date')) {
        urlParams.delete('date_type');
        setSearchParams(urlParams);
      }
    }
  }, [allChecked, multiSelectInvoiceItems]);
  function countTrueValues(obj: any): number {
    let count = 0;
    for (let key in obj) {
      if (obj[key] === true) {
        count++;
      }
    }
    return count;
  }

  const getQueryInvoicePartner = (params: any) => {
    const type = params.get('type') || '';
    const request = params.get('request') || '';
    const search = params.get('search') || '';
    const start_date = params.get('start_date') || '';
    const end_date = params.get('end_date') || '';
    const companies: any = params.get('companies') || '';
    const vehicleType: any = params.get('vehicle_type') || '';
    const vendor_ids: any = params.get('vendor_ids') || '';
    const dsp_ids: any = params.get('dsp_ids') || '';
    const csr_ids: any = params.get('csr_ids') || '';
    const technician_ids: any = params.get('technician_ids') || '';
    const only_single: any = params.get('only_single') || '';
    const invoice_status: any = params.get('invoice_status') || '';
    const services: any = params.get('services') || '';
    const partner_ids: any = params.get('partner_ids') || '';
    const date_type: any = params.get('date_type') || '';
    const station_ids: any = params.get('station_ids') || '';
    const isVendorRequestReport = window.location.href.includes('vendorRequestReport');
    let query = '';
    const page_number = params.get('page_number');
    if (page_number) {
      query += `page_number=${page_number}&`;
    }
    if (station_ids) {
      query += `station_ids=${station_ids}&`;
    }

    if (only_single) {
      query += `only_single=${only_single}&`;
    }
    if (date_type && !isVendorRequestReport) {
      query += `date_type=${date_type}&`;
    }
    if (vendor_ids) {
      query += `vendor_ids=${vendor_ids}&`;
    }
    if (dsp_ids) {
      query += `dsp_ids=${dsp_ids}&`;
    }

    if (technician_ids) {
      query += `technician_ids=${technician_ids}&`;
    }
    if (csr_ids) {
      query += `csr_ids=${csr_ids}&`;
    }
    if (start_date) {
      query += `start_date=${start_date}&`;
    }
    if (end_date) {
      query += `end_date=${end_date}&`;
    }
    if (companies) {
      query += `companies=${companies}&`;
    }
    if (vehicleType) {
      query += `vehicle_type=${vehicleType}&`;
    }
    if (search) {
      query += `search=${search}&`;
    }
    if (invoice_status) {
      query += `${isVendorRequestReport ? 'request_status' : 'invoice_status'}=${invoice_status}&`;
    }
    if (services) {
      query += `services=${services}&`;
    }
    if (partner_ids) {
      query += `partner_ids=${partner_ids}&`;
    }
    switch (type) {
      case 'requests': {
        query += `request_type=Pending_request`;
        return query;
      }
      case 'accepted': {
        query += `request_type=accepted_request`;
        return query;
      }
      case 'assigned': {
        switch (request) {
          case 'Assigned To Technician':
            query += `status_ids=${JSON.stringify([5])}`;
            return query;
          case 'Technician Started Working':
            query += `status_ids=${JSON.stringify([6])}`;
            return query;
          case 'Job Completed/ Upload Proof':
            query += `status_ids=${JSON.stringify([7])}`;
            return query;
          case 'Approval Pending':
            query += `status_ids=${JSON.stringify([8])}`;
            return query;
          default:
            query += `status_ids=${JSON.stringify([5, 6, 7, 8])}`;
            return query;
        }
      }
      case 'approved': {
        switch (request) {
          case 'Proofs Accepted':
            query += `request_type=Proofs Accepted`;
            return query;
          case 'Invoice Received':
            query += `request_type=Invoice Received`;
            return query;
          case 'Payment Completed':
            query += `request_type=Payment Completed`;
            return query;
          default:
            query += `request_type=approved`;
            return query;
        }
      }
      case 'dispute': {
        query += `request_type=dispute`;
        return query;
      }
      case 'rejected': {
        switch (request) {
          case 'Declined by you':
            query += `rejected=true`;
            return query;

          case 'Rejected':
            query += `status_ids=${JSON.stringify([10])}`;
            return query;

          default:
            query += `status_ids=${JSON.stringify([10])}&rejected=true`;
            return query;
        }
      }

      case 'paymentConfirmed': {
        query += `request_type=paymentProcessed`;
        return query;
      }
      default:
        return query;
    }
  };
  const handleSelectRow = (row: any) => {
    if (
      !multiSelectInvoiceItems.some((selectedRow: any) => selectedRow.invoice_id === row.invoice_id)
    ) {
      setMultiInvoiceItems([...multiSelectInvoiceItems, row]);
    } else {
      setMultiInvoiceItems(
        multiSelectInvoiceItems.filter(
          (selectedRow: any) => selectedRow.invoice_id !== row.invoice_id
        )
      );
    }
  };
  const handleSelectRowReport = (row: any) => {
    const isRowSelected = multiSelectInvoiceItems.some(
      (selectedRow: any) =>
        selectedRow.request_id === row.request_id &&
        selectedRow.attempt === row.attempt &&
        selectedRow.status === row.status
    );

    if (!isRowSelected) {
      setMultiInvoiceItems([...multiSelectInvoiceItems, row]);
    } else {
      setMultiInvoiceItems(
        multiSelectInvoiceItems.filter(
          (selectedRow: any) =>
            !(
              selectedRow?.request_id === row?.request_id &&
              selectedRow?.attempt === row?.attempt &&
              selectedRow?.status === row?.status
            )
        )
      );
    }
  };
  return (
    <>
      <div className={`w-full mx-3 ${customizeView && totalItems > 20 ? 'h-[97.5%]' : 'h-[100%]'}`}>
        <FilterModal
          openDownload={openDownload}
          setDownloadOpen={setDownloadOpen}
          downloadType={downloadType}
          totalRevenue={revenueModel?.revenue?.total_amount}
          revenueType={revenueType}
        />
        <div
          className={`w-full ${
            customizeView ? 'h-[8%]' : 'h-[6%]'
          } flex justify-between mt-[0.8rem] mb-[0.8rem]`}>
          <div className='ml-[-7rem] flex items-center cursor-pointer'>
            <FilterDrawer count={count} setCount={setCount} setCurrentPage={setCurrentPage} />
            <div className='ml-[0.8rem]'>
              <Download setDownloadOpen={setDownloadOpen} setType={setDownloadType} />
            </div>
          </div>{' '}
          <div className='flex items-center'>
            <div className='flex border-[0.1rem] border-borderColor rounded-full  pl-[0.4rem] bg-primary-lighter h-[2.6rem]'>
              <div className='flex items-center '>
                <CalenderDropdown
                  setRevenueType={setRevenueType}
                  count={count}
                  setCount={setCount}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  setCurrentPage={setCurrentPage}
                />
                {!isVendorRequestReport && (
                  <div className='border-gray-400  border-[0.05rem] ml-[0.2rem] h-[2rem]' />
                )}
              </div>
              {user.role === 'fleetmax_partner' &&
                urlParams.get('vendor_ids') &&
                !isVendorRequestReport && (
                  <div className='flex items-center pl-[1rem] cursor-pointer  '>
                    <div className='text-tableTextColor font-bold pr-[0.4rem] text-[1rem]'>
                      Vendor Revenue{' '}
                      {showVendorPopover && (
                        <div className='absolute bg-revenueModelBgColor text-black p-2 rounded shadow-lg mt-3 text-[0.9rem] text-tableTextColor z-20'>
                          {urlParams.get('date_type') === 'all'
                            ? revenueModel?.vendors_revenue?.revenue_per_vendor.map((item: any) => {
                                return (
                                  <div>
                                    <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white capitalize '>
                                      <div>{item?.vendor?.name}</div>
                                      <div>
                                        ${parseFloat(item?.revenue?.total_amount || 0).toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : revenueModel?.vendors_revenue?.revenue?.revenue_per_vendor.map(
                                (item: any) => {
                                  return (
                                    <div>
                                      <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white capitalize '>
                                        <div>{item?.vendor?.name}</div>
                                        <div>
                                          $
                                          {parseFloat(item?.revenue?.subtotal_amount || 0).toFixed(
                                            2
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                        </div>
                      )}
                    </div>
                    <div className='text-tableTextColor font-bold pr-[0.3rem] text-[1.2rem]'>
                      $
                      {parseFloat(
                        urlParams.get('date_type') === 'all'
                          ? revenueModel?.vendors_revenue?.total_amount || 0
                          : revenueModel?.vendors_revenue?.revenue?.total_amount || 0
                      ).toFixed(2)}
                    </div>
                    {parseInt(revenueModel?.vendors_revenue?.growth?.replace('%', '').trim()) >
                    0 ? (
                      <div className='text-[1rem] text-paymentSuccesfulColor font-bold px-[0.5rem] py-[0.2rem] bg-profitBgColor rounded-full mx-[0.4rem] '>
                        <img
                          src='../../svg/profitArrow.svg'
                          alt='InfoIcon'
                          className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                          onMouseEnter={() => setShowVendorPopover(true)}
                          onMouseLeave={() => setShowVendorPopover(false)}
                        />

                        {revenueModel?.vendors_revenue?.growth}
                      </div>
                    ) : (
                      parseInt(revenueModel?.vendors_revenue?.growth?.replace('%', '').trim()) <
                        0 && (
                        <div className='text-[1rem] text-red-600 font-bold px-[0.5rem] py-[0.2rem] bg-red-200 rounded-full mx-[0.4rem] '>
                          <img
                            src='../../svg/lossArrow.svg'
                            alt='InfoIcon'
                            className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                            onMouseEnter={() => setShowVendorPopover(true)}
                            onMouseLeave={() => setShowVendorPopover(false)}
                          />
                          {Math?.abs(
                            parseFloat(
                              revenueModel?.vendors_revenue?.growth?.replace('%', '').trim()
                            )
                          )}
                          %
                        </div>
                      )
                    )}
                    {(revenueModel?.vendors_revenue?.revenue?.revenue_per_vendor?.length > 0 ||
                      revenueModel?.vendors_revenue?.revenue_per_vendor?.length > 0) && (
                      <div>
                        <div className='flex items-center'>
                          <img
                            src='../../svg/info.svg'
                            alt='infoIcon'
                            className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                            onMouseEnter={() => setShowVendorPopover(true)}
                            onMouseLeave={() => setShowVendorPopover(false)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {!isVendorRequestReport && (
                <div className='flex items-center pl-[1rem] cursor-pointer  '>
                  <div className='text-tableTextColor font-bold pr-[0.4rem] text-[1rem]'>
                    My Revenue{' '}
                    {showGrossPopover && (
                      <div className='absolute right-5 bg-revenueModelBgColor text-black p-2 rounded shadow-lg mt-3 text-[0.9rem] text-tableTextColor z-20'>
                        <div>
                          <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                            <div>Sub Total</div>
                            <div>
                              ${parseFloat(revenueModel?.revenue?.subtotal_amount || 0).toFixed(2)}
                            </div>
                          </div>{' '}
                          <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                            <div>Sales Tax</div>
                            <div>
                              ${parseFloat(revenueModel?.revenue?.total_tax_amount || 0).toFixed(2)}
                            </div>
                          </div>{' '}
                          <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white whitespace-nowrap'>
                            <div>Estimated Total</div>
                            <div>
                              ${parseFloat(revenueModel?.revenue?.total_amount || 0).toFixed(2)}
                            </div>
                          </div>{' '}
                          {revenueModel?.previous_revenue > 0 && (
                            <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-white'>
                              <div>
                                {revenueType === 'last_7_days'
                                  ? 'Previous 7 days'
                                  : 'Previous 30 days'}
                              </div>
                              <div>
                                ${parseFloat(revenueModel?.previous_revenue || 0).toFixed(2)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='text-tableTextColor font-bold pr-[0.3rem] text-[1.2rem]'>
                    ${parseFloat(revenueModel?.revenue?.total_amount || 0).toFixed(2)}
                  </div>
                  {parseInt(revenueModel?.growth?.replace('%', '').trim()) > 0 ? (
                    <div className='text-[1rem] text-paymentSuccesfulColor font-bold px-[0.5rem] py-[0.2rem] bg-profitBgColor rounded-full mx-[0.4rem] '>
                      <img
                        src='../../svg/profitArrow.svg'
                        alt='InfoIcon'
                        className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                        onMouseEnter={() => setShowGrossPopover(true)}
                        onMouseLeave={() => setShowGrossPopover(false)}
                      />

                      {revenueModel?.growth}
                    </div>
                  ) : (
                    parseInt(revenueModel?.growth?.replace('%', '').trim()) < 0 && (
                      <div className='text-[1rem] text-red-600 font-bold px-[0.5rem] py-[0.2rem] bg-red-200 rounded-full mx-[0.4rem] '>
                        <img
                          src='../../svg/lossArrow.svg'
                          alt='InfoIcon'
                          className='inline-block h-[1.1rem] w-[1.1rem] pr-[0.3rem] pb-[0.1rem]'
                          onMouseEnter={() => setShowGrossPopover(true)}
                          onMouseLeave={() => setShowGrossPopover(false)}
                        />
                        {Math?.abs(parseFloat(revenueModel?.growth?.replace('%', '').trim()))}%
                      </div>
                    )
                  )}
                  <div>
                    <div className='flex items-center'>
                      <img
                        src='../../svg/info.svg'
                        alt='infoIcon'
                        className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        onMouseEnter={() => setShowGrossPopover(true)}
                        onMouseLeave={() => setShowGrossPopover(false)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`w-full ${
            urlParams.get('page_number') ? `${totalItems <= 20 ? 'h-[92%]' : 'h-[85%]'}` : 'h-[92%]'
          }`}>
          <div className='border-[0.1rem] rounded-xl  border-borderColor h-[100%] mt-[1rem] overflow-y-auto'>
            <table className='w-full text-sm text-left'>
              <thead className='text-xs text-tableHeading sticky top-0 bg-white z-10 cursor-default '>
                <tr>
                  <th scope='col'>
                    <div
                      className={`flex items-center justify-start pl-[0.9rem] w-[1rem] ${
                        data?.length === 0 && 'mr-[0.8rem]'
                      }`}>
                      {/* <input
                        id='checkbox-all-search'
                        type='checkbox'
                        className='w-[1rem] p-[0.5rem] text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer ml-[0.8rem] '
                        onChange={handleSelectAll}
                        checked={allChecked}
                      /> */}
                      <FormControlLabel
                        key={'1'}
                        control={
                          <Checkbox
                            id='checkbox-all-search'
                            onChange={handleSelectAll}
                            checked={allChecked}
                            disableRipple
                            color='default'
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                          />
                        }
                        label={
                          <div className='py-[0.5rem] text-center text-[1.1rem] font-bold'>All</div>
                        }
                      />
                      <label htmlFor='checkbox-all-search' className='sr-only '>
                        All
                      </label>{' '}
                      {/* <div className=' pl-[0.6rem] py-[0.5rem] text-center text-[1.1rem]'>All</div> */}
                    </div>
                  </th>
                  {invoiceView.date && <Heading title='Date' />}
                  {invoiceView.invoice_id && <Heading title='Invoice ID' />}
                  {invoiceView.request_id && <Heading title='Request ID' />}
                  {invoiceView.csr && <Heading title='CSR' />}{' '}
                  {invoiceView.dsp_shortcode && <Heading title='DSP Shortcode' />}
                  {invoiceView.station_code && <Heading title='Station Code' />}
                  {invoiceView.dsp_name && <Heading title='DSP Name' />}
                  {user.role !== 'fleetmax_vendor' && invoiceView?.vendor && (
                    <Heading title='Vendor' />
                  )}
                  {invoiceView?.van_name && <Heading title='Van Name' />}{' '}
                  {invoiceView?.van_type && <Heading title='Van Type' />}{' '}
                  {invoiceView?.vin_number && <Heading title='VIN Number' />}
                  {invoiceView?.technician && <Heading title='Technician' />}
                  {invoiceView?.partner && <Heading title='Partner' />}
                  {invoiceView?.miles && <Heading title='Last PM' />}
                  {invoiceView?.current_miles && <Heading title='Current Miles' />}
                  {invoiceView?.miles_till_inspection && <Heading title='Miles Till Inspection' />}
                  {invoiceView?.service_items && <Heading title='Service' />}
                  {invoiceView?.amount && <Heading title='Amount' />}
                  {invoiceView?.status && (
                    <th scope='col' className=' text-center py-[0.5rem] pr-[0.6rem] text-[1.1rem]'>
                      Status
                    </th>
                  )}
                  {invoiceView?.service_completion_date && (
                    <Heading title='Service Completion Date' />
                  )}
                  {invoiceView?.revenue && <Heading title='Revenue' />}
                </tr>
              </thead>
              {data?.length > 0 && (
                <tbody className='border-y-[0.1rem]  border-borderColor shadow-[0_0_0_0.1_theme(borderColor.DEFAULT)] text-[1.1rem] text-tableTextColor font-bold h-[5rem]'>
                  {data.map((item: any, index: any) => (
                    <tr className='border-x-transparent border-x-[0.8rem] border-b-[0.1rem] border-b-borderColor cursor-default '>
                      <td className='w-[1rem] h-[1rem]'>
                        <div className='flex items-center justify-start pl-[0.5rem]'>
                          {/* <input
                            id={item.invoice_id}
                            type='checkbox'
                            className='w-[1rem] h-[1rem] text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer'
                            value={item.invoice_id}
                            checked={multiSelectInvoiceItems.some(
                              (selectedRow: any) => selectedRow.invoice_id === item.invoice_id
                            )}
                            onChange={() => handleSelectRow(item)}
                          />

                          <label htmlFor='checkbox-table-search-1' className='sr-only'>
                            checkbox
                          </label> */}
                          {isVendorRequestReport ? (
                            <FormControlLabel
                              key={'1'}
                              control={
                                <Checkbox
                                  id={item.request_id}
                                  value={item.request_id}
                                  checked={multiSelectInvoiceItems.some(
                                    (selectedRow: any) =>
                                      selectedRow?.request_id === item?.request_id &&
                                      selectedRow?.attempt === item?.attempt &&
                                      selectedRow?.status === item?.status
                                  )}
                                  onChange={() => handleSelectRowReport(item)}
                                  disableRipple
                                  color='default'
                                  checkedIcon={<BpCheckedIcon />}
                                  icon={<BpIcon />}
                                />
                              }
                              label={''}
                            />
                          ) : (
                            <FormControlLabel
                              key={'1'}
                              control={
                                <Checkbox
                                  id={item.invoice_id}
                                  value={item.invoice_id}
                                  checked={multiSelectInvoiceItems.some(
                                    (selectedRow: any) => selectedRow.invoice_id === item.invoice_id
                                  )}
                                  onChange={() => handleSelectRow(item)}
                                  disableRipple
                                  color='default'
                                  checkedIcon={<BpCheckedIcon />}
                                  icon={<BpIcon />}
                                />
                              }
                              label={''}
                            />
                          )}
                        </div>
                      </td>
                      {invoiceView?.date && (
                        <td className='px-[0.1rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem] '>
                            <div className='w-[6.5rem] text-ellipsis overflow-hidden capitalize text-tableTextColor'>
                              {item.date}
                            </div>
                          </div>
                        </td>
                      )}
                      {invoiceView?.invoice_id && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <div className='w-[5rem] text-ellipsis overflow-hidden capitalize text-center'>
                              {item.invoice_id}
                            </div>
                          </div>
                        </td>
                      )}
                      {invoiceView?.request_id && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <div className=' text-ellipsis overflow-hidden text-center capitalize cursor-pointer  '>
                              <div
                                className='border-transparent hover:border-b-tableLinkColor border-b-[0.15rem] text-tableLinkColor text-center'
                                onClick={() => {
                                  setShowModel({
                                    show: true,
                                    data: {
                                      ...finalData[index],
                                      id: item.request_id,
                                    },
                                  });
                                }}>
                                {item.request_id}
                              </div>
                            </div>
                          </div>
                        </td>
                      )}
                      {invoiceView?.csr && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.csr}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '6rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.dsp_shortcode && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.dsp_shortcode}`}
                              palcement={'bottom'}
                              style={
                                'w-[5rem] text-ellipsis overflow-hidden capitalize text-center'
                              }
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.station_code && (
                        <td className='px-[0.2rem]  py-4'>
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.station_code}`}
                              palcement={'bottom'}
                              style={'w-[5rem] text-ellipsis overflow-hidden text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.dsp_name && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.dsp_name}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '6rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {user.role !== 'fleetmax_vendor' && invoiceView?.vendor && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.vendor}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '7rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.van_name && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.van_name}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '7rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}{' '}
                      {invoiceView?.van_type && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.van_type}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '6rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}{' '}
                      {invoiceView?.vin_number && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item.vin_number}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '7rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.technician && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.technician}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '7rem'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.partner && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.partner || '---'}`}
                              palcement={'bottom'}
                              style={
                                'w-[5rem] text-ellipsis overflow-hidden capitalize text-center'
                              }
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.miles && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.miles || '---'}`}
                              palcement={'bottom'}
                              style={
                                'w-[5rem] text-ellipsis overflow-hidden capitalize text-center'
                              }
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.current_miles && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.current_miles || '---'}`}
                              palcement={'bottom'}
                              style={
                                'w-[5rem] text-ellipsis overflow-hidden capitalize text-center'
                              }
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.miles_till_inspection && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.miles_till_inspection || '---'}`}
                              palcement={'bottom'}
                              style={'text-ellipsis overflow-hidden capitalize text-center'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '7rem'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.service_items && (
                        <td className='px-[0.2rem]  py-4'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem] h-[1.2rem]'>
                            <div>
                              <div>
                                <div className='flex justify-center gap-2'>
                                  {(
                                    rawData?.[index]?.invoice?.services ||
                                    rawData?.[index]?.services
                                  )?.length === 1 && (
                                    <>
                                      <Tootltip
                                        followCursor
                                        placement={'bottom'}
                                        title={
                                          <div>
                                            {(
                                              rawData?.[index]?.invoice?.services ||
                                              rawData?.[index]?.services
                                            )?.map((item: any) => (
                                              <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                                <div>{item?.name}</div>
                                                {!isVendorRequestReport && (
                                                  <div className='text-tableLinkColor '>
                                                    {item?.amount === 'N/A'
                                                      ? 'N/A'
                                                      : `$${item?.amount}`}
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        }>
                                        <p
                                          className={`max-w-[${
                                            countTrueValues(invoiceView) < 5 ? '14rem' : '10rem'
                                          }] text-ellipsis whitespace-nowrap overflow-hidden capitalize text-center `}>
                                          {item?.service_items}
                                        </p>
                                      </Tootltip>
                                      {!isVendorRequestReport && (
                                        <Tooltip
                                          title={
                                            <div>
                                              {(
                                                rawData?.[index]?.invoice?.services ||
                                                rawData?.[index]?.services
                                              )?.map((item: any) => (
                                                <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                                  <div>{item?.name}</div>
                                                  {!isVendorRequestReport && (
                                                    <div className='text-tableLinkColor '>
                                                      {item?.amount === 'N/A'
                                                        ? 'N/A'
                                                        : `$${item?.amount}`}
                                                    </div>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                          }>
                                          <img
                                            src='../../svg/info.svg'
                                            alt='infoIcon'
                                            className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {(rawData?.[index]?.invoice?.services || rawData?.[index]?.services)
                                ?.length > 1 && (
                                <div className='max-w-[10rem] text-ellipsis whitespace-nowrap overflow-hidden capitalize text-center '>
                                  {item?.service_items}
                                </div>
                              )}
                              {(rawData?.[index]?.invoice?.services || rawData?.[index]?.services)
                                ?.length > 1 && (
                                <Tooltip
                                  followCursor
                                  title={
                                    <div>
                                      {(
                                        rawData?.[index]?.invoice?.services ||
                                        rawData?.[index]?.services
                                      )?.map((item: any) => (
                                        <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                          <div>{item?.name}</div>
                                          {!isVendorRequestReport && (
                                            <div className='text-tableLinkColor '>
                                              {item?.amount === 'N/A'
                                                ? 'N/A'
                                                : `$${parseFloat(item?.amount || 0).toFixed(2)}`}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  }>
                                  <div>
                                    {(
                                      rawData?.[index]?.invoice?.services ||
                                      rawData?.[index]?.services
                                    )?.length > 0 && (
                                      <div className='text-[0.9rem] text-tableTextColor-600 text-center'>
                                        {' '}
                                        +
                                        {(
                                          rawData?.[index]?.invoice?.services ||
                                          rawData?.[index]?.services
                                        )?.length - 1}{' '}
                                        Services{' '}
                                        <img
                                          src='../../svg/info.svg'
                                          alt='infoIcon'
                                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Tooltip>
                              )}
                              {(rawData?.[index]?.invoice?.services || rawData?.[index]?.services)
                                ?.length === 0 && <>{'N/A'}</>}
                            </div>
                          </div>
                        </td>
                      )}
                      {invoiceView?.amount && (
                        <InvoiceAmount rawData={rawData} index={index} item={item} />
                      )}
                      {invoiceView?.status && (
                        <InvoiceStatus
                          item={item}
                          subStatus={
                            invoiceView?.request_substatus ? item?.request_substatus : false
                          }
                          totalDataLength={countTrueValues(invoiceView)}
                        />
                      )}
                      {invoiceView?.service_completion_date && (
                        <td className=' w-[15rem] py-2'>
                          {' '}
                          <div className='flex items-center justify-center border-l-borderColor border-l-[0.2rem]'>
                            <TitleTooltip
                              title={`${item?.service_completion_date || '---'}`}
                              palcement={'bottom'}
                              width={countTrueValues(invoiceView) < 5 ? '12rem' : '8rem'}
                              style={' text-ellipsis overflow-hidden capitalize text-center'}
                            />
                          </div>
                        </td>
                      )}
                      {invoiceView?.revenue && (
                        <td className=' w-[10rem] py-2'>
                          {' '}
                          <Tooltip
                            followCursor
                            title={
                              item?.revenue !== 'N/A' ? (
                                <div>
                                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                    <div>Sub Total</div>
                                    <div className='text-primary '>{item?.sub_total_revenue}</div>
                                  </div>
                                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                    <div>Sales Tax</div>
                                    <div className='text-primary '>{item?.tax_amount_revenue}</div>
                                  </div>
                                  <div className='flex justify-between px-[0.2rem] py-[0.2rem] gap-4 text-[0.9rem] capitalize'>
                                    <div>Estimated Total</div>
                                    <div className='text-primary '>{item?.revenue}</div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            }>
                            <div className='text-[1.1rem] text-tableTextColor-600 text-center flex items-center gap-2 justify-center'>
                              <p className='max-w-[8rem] text-ellipsis overflow-hidden'>
                                {item?.revenue || '4500'}
                              </p>
                              {item?.revenue !== 'N/A' && (
                                <img
                                  src='../../svg/info.svg'
                                  alt='infoIcon'
                                  className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                                />
                              )}
                            </div>
                          </Tooltip>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {data?.length === 0 && (
              <div className='flex justify-center items-center text-[1.1rem] text-gray h-[80%] rounded-l-[0.5rem] '>
                <i className='pi pi-info-circle mr-2'></i>
                No Data Found
              </div>
            )}
          </div>
        </div>
        {user?.role === 'fleetmax_csr' ? (
          <>
            {showModel?.show && (
              <OpenCSRModel
                item={showModel.data}
                handleClose={() => setShowModel({ show: false, data: {} })}
                refetch={fetchPartnerInvoiceData}
              />
            )}
          </>
        ) : (
          <>
            {showModel?.show && (
              <OpenModel
                item={showModel.data}
                handleClose={() => setShowModel({ show: false, data: {} })}
                refetch={fetchPartnerInvoiceData}
              />
            )}
          </>
        )}

        {urlParams.get('page_number') && (
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};

export default InvoiceTable;
