import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import { Tooltip, Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import VendorAPI from '../../../../../api/vendorApi';
import { RootState } from '../../../../../state/reducers';
import ChatAPI from '../../../../../api/ChatAPI';
import CsrAPI from '../../../../../api/csrApi';
import Tootltip from '../../../../../components/BarData/Tootltip';
import PartnerApi from '../../../../../api/partnerSpecificApi';
import getStatus, { getStatusName } from './getStatus';
import useFilterState from './useFilterState';
import PartnerAPI from '../../../../../api/partnerApi';

export default function FilterDrawer({ count, setCount, setCurrentPage }: any) {
  const [urlParams, setSearchParams]: any = useSearchParams();
  const { user, invoiceView }: any = useSelector((state: RootState) => state.userState);

  const {
    open,
    isOpenVendor,
    isOpenCsr,
    isOpenPartner,
    isOpenStatus,
    isOpenServices,
    isOpenStationCode,
    isOpenTechnician,
    isOpenDsp,
    checkData,
    checkCsrData,
    checkPartnerData,
    checkStatusData,
    checkServicesData,
    checkStationCode,
    checkTechnicianData,
    checkDspData,
    allVendorData,
    allServicesData,
    allStationCodeData,
    allTechnicianData,
    allDspData,
    allCsrData,
    allPartnerData,
    setOpen,
    setIsOpenVendor,
    setIsOpenCsr,
    setIsOpenPartner,
    setIsOpenStatus,
    setIsOpenServices,
    setIsOpenStationCode,
    setIsOpenTechnician,
    setIsOpenDsp,
    setCheckData,
    setCheckCsrData,
    setCheckPartnerData,
    setCheckStatusData,
    setCheckServicesData,
    setCheckStationCode,
    setCheckTechnicianData,
    setCheckDspData,
    setAllVendorData,
    setAllServicesData,
    setAllStationCodeData,
    setAllTechnicianData,
    setAllDspData,
    setAllCsrData,
    setAllPartnerData,
  }: any = useFilterState();
  const isVendorRequestReport = window.location.href.includes('/vendorRequestReport');

  useEffect(() => {
    // services
    if (user?.role === 'fleetmax_csr') {
      CsrAPI.getService().then((res) => {
        if (res.status === 200) {
          const finalData = res.data.data.map((item: any) => ({
            name: item.name,
            id: item?.id || '33',
          }));
          setAllServicesData(finalData);
        }
      });
    } else {
      VendorAPI.getVendorFullDetail({ id: '' }).then((res: any) => {
        if (res.status === 200) {
          const responseData = res.data?.data[Object.keys(res.data?.data)[0]]['services'].reduce(
            (acc: any, currentItem: any) => {
              if (!acc.find((item: any) => item.name === currentItem.name)) {
                acc.push({
                  id: currentItem.id,
                  name: currentItem.name,
                });
              }
              return acc;
            },
            []
          );
          setAllServicesData(responseData);
        }
      });
    }
    if (user.role !== 'fleetmax_csr') {
      if (user.role === 'fleetmax_vendor') {
        ChatAPI.getChatUserNames('vendor')
          .then((res) => {
            if (res.status === 200) {
              const responseData = res.data.data;
              setAllVendorData(responseData);
            }
          })
          .catch((err) => console.log(err));
      } else {
        PartnerApi.getAllVendor().then((res: any) => {
          if (res.status === 200) {
            const responseData = res.data.data.map((item: any) => {
              return {
                user_name: item?.vendor?.name,
                id: item?.vendor?.id,
                type: 'fleetmax_vendor',
              };
            });
            setAllVendorData(responseData);
          }
        });
      }
    }
    if (user?.role === 'fleetmax_csr') {
      VendorAPI.getAllData({})
        .then((res) => {
          if (res.status === 200) {
            const vendors = res.data.data
              .map((item: any) => {
                return {
                  user_name: item?.vendor_name,
                  id: item?.vendor_id,
                  type: 'fleetmax_vendor',
                };
              })
              .filter(Boolean)
              .flat();

            setAllVendorData(vendors);
          } else {
            setAllVendorData([]);
          }
        })
        .catch((err) => console.log(err));
      ChatAPI.getChatUserNames('technician')
        .then((res) => {
          if (res?.status === 200) {
            const responseData = res?.data?.data
              .map((item: any) => ({
                user_name: item?.user_name,
                id: item?.id,
                vendor_name: item?.vendor_name,
              }))
              .filter(Boolean)
              .flat();
            setAllTechnicianData(responseData);
          } else {
            setAllTechnicianData([]);
          }
        })
        .catch((err) => console.log(err));
      PartnerAPI.getAllPartnerData({ data: '' })
        .then((res: any) => {
          if (res.status === 200) {
            const responseData: any = res.data.data.map((item: any) => {
              return {
                user_name: item?.partner?.name,
                id: item?.partner?.id,
              };
            });
            setAllPartnerData(responseData);
          } else {
            setAllPartnerData([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      VendorAPI.getVendorsTechnician()
        .then((res: any) => {
          if (res.status === 200) {
            const responseData = res.data.data.technicians_details.map((item: any) => {
              return {
                user_name: item?.technician_name,
                id: item?.technician_id,
              };
            });

            setAllTechnicianData(responseData);
          } else {
            setAllTechnicianData([]);
          }
        })
        .catch((err) => console.log(err));
    }
    if (user?.role === 'fleetmax_csr') {
      ChatAPI.getChatUserNames('dsp')
        .then((res: any) => {
          if (res.status === 200) {
            const responseData = res?.data?.data.map((item: any) => {
              return {
                user_name: item?.dsp?.company_name,
                id: item?.dsp?.company_id,
              };
            });
            setAllDspData(responseData);
          } else {
            setAllDspData([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      ChatAPI.getChatUserNamesVendor('dsp')
        .then((res: any) => {
          if (res.status === 200) {
            const responseData = res?.data?.data.map((item: any) => {
              return {
                user_name: item?.dsp?.company_name,
                id: item?.dsp?.company_id,
              };
            });
            setAllDspData(responseData);
          } else {
            setAllDspData([]);
          }
        })
        .catch((err) => console.log(err));
    }

    CsrAPI.get({ data: '' })
      .then((res: any) => {
        if (res.status === 200) {
          const responseCsr: any = Object.keys(res.data.data).map((key) => {
            return {
              user_name: res?.data?.data[key]?.csr_name,
              id: res?.data?.data[key]?.csr_id,
              type: 'fleetmax_csr',
            };
          });
          setAllCsrData(responseCsr);
        } else {
          setAllCsrData([]);
        }
      })
      .catch((err) => console.log(err));
    if (user?.role === 'fleetmax_csr') {
      CsrAPI.getStationCOdes()
        .then((res: any) => {
          if (res.status === 200) {
            const responseDspCode: any = res?.data?.data.map((item: any) => {
              return {
                user_dsp_code: item?.station_code,
                user_company_id: item?.company_details?.map((item: any) => item.id),
              };
            });
            setAllStationCodeData(responseDspCode);
          } else {
            setAllStationCodeData([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      VendorAPI.getRequestCompanies(user?.account_id)
        .then((res: any) => {
          if (res.status === 200) {
            const responseDspCode: any = res?.data?.data.map((item: any) => {
              return {
                user_dsp_code: item?.station_code,
                user_company_id: item?.company_details?.map((item: any) => item.id),
              };
            });
            setAllStationCodeData(responseDspCode);
          } else {
            setAllStationCodeData([]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(() => {
    if (count === 0) {
      handleCancelFilter();
    }
  }, [count, urlParams]);
  const handleApplyFilter = () => {
    let filteredObject = Object.fromEntries(
      Object.entries(checkData).filter(([key, value]) => value === true)
    );
    let filteredCsrObject = Object.fromEntries(
      Object.entries(checkCsrData).filter(([key, value]) => value === true)
    );
    let filteredTechnicianObject = Object.fromEntries(
      Object.entries(checkTechnicianData).filter(([key, value]) => value === true)
    );
    let filteredDspObject = Object.fromEntries(
      Object.entries(checkDspData).filter(([key, value]) => value === true)
    );
    let filteredStatusObject = Object.fromEntries(
      Object.entries(checkStatusData).filter(([key, value]) => value === true)
    );
    let filteredServicesObject = Object.fromEntries(
      Object.entries(checkServicesData).filter(([key, value]) => value === true)
    );
    let filteredStationCodesObject = Object.fromEntries(
      Object.entries(checkStationCode).filter(([key, value]) => value === true)
    );
    let filteredPartnerObject = Object.fromEntries(
      Object.entries(checkPartnerData).filter(([key, value]) => value === true)
    );

    if (Object.keys(filteredObject).length !== 0) {
      urlParams.set('vendor_ids', JSON.stringify(Object.keys(filteredObject)));
    }
    if (!isVendorRequestReport && user?.role !== 'fleetmax_csr') {
      if (Object.keys(filteredCsrObject).length !== 0) {
        if (user?.role === 'fleetmax_csr') {
          urlParams.set('partner_ids', JSON.stringify(Object.keys(filteredCsrObject)));
        } else {
          urlParams.set('csr_ids', JSON.stringify(Object.keys(filteredCsrObject)));
        }
      }
    }
    if (Object.keys(filteredTechnicianObject).length !== 0) {
      urlParams.set('technician_ids', JSON.stringify(Object.keys(filteredTechnicianObject)));
    }
    if (Object.keys(filteredDspObject).length !== 0) {
      urlParams.set('dsp_ids', JSON.stringify(Object.keys(filteredDspObject)));
    }
    if (Object.keys(filteredStatusObject).length !== 0) {
      urlParams.set('invoice_status', JSON.stringify(Object.keys(filteredStatusObject)));
    }
    if (Object.keys(filteredServicesObject).length !== 0) {
      urlParams.set('services', JSON.stringify(Object.keys(filteredServicesObject)));
    }
    if (Object.keys(filteredStationCodesObject).length !== 0) {
      urlParams.set('station_ids', JSON.stringify([...Object.keys(filteredStationCodesObject)]));
    }
    if (Object.keys(filteredPartnerObject).length !== 0) {
      urlParams.set('partner_ids', JSON.stringify([...Object.keys(filteredPartnerObject)]));
    }
    if (Object.keys(filteredServicesObject).length === 0) {
      urlParams.delete('services');
    }
    if (Object.keys(filteredStationCodesObject).length === 0) {
      urlParams.delete('station_ids');
    }
    if (Object.keys(filteredObject).length === 0) {
      urlParams.delete('vendor_ids');
    }
    if (Object.keys(filteredStatusObject).length === 0) {
      urlParams.delete('invoice_status');
    }
    if (Object.keys(filteredPartnerObject).length === 0) {
      urlParams.delete('partner_ids');
    }
    if (!isVendorRequestReport && user?.role !== 'fleetmax_csr') {
      if (Object.keys(filteredCsrObject).length === 0) {
        if (user?.role === 'fleetmax_csr') {
          urlParams.delete('partner_ids');
        } else {
          urlParams.delete('csr_ids');
        }
      }
    }
    if (Object.keys(filteredTechnicianObject).length === 0) {
      urlParams.delete('technician_ids');
    }
    if (Object.keys(filteredDspObject).length === 0) {
      urlParams.delete('dsp_ids');
    }
    urlParams.set('page_number', '1');
    setCurrentPage(1);
    setSearchParams(urlParams);
    setCount(
      Object.keys(filteredCsrObject).length +
        Object.keys(filteredTechnicianObject).length +
        Object.keys(filteredObject).length +
        Object.keys(filteredStatusObject).length +
        Object.keys(filteredServicesObject).length +
        Object.keys(filteredStationCodesObject).length +
        Object.keys(filteredDspObject).length +
        Object.keys(filteredPartnerObject).length
    );
    setOpen(false);
  };
  const handleCancelFilter = () => {
    urlParams.delete('vendor_ids');
    urlParams.delete('technician_ids');
    urlParams.delete('csr_ids');
    urlParams.delete('invoice_status');
    urlParams.delete('services');
    urlParams.delete('partner_ids');
    urlParams.delete('station_ids');
    urlParams.delete('dsp_ids');
    urlParams.delete('partner_ids');
    setSearchParams(urlParams);
    setCheckData({});
    setCheckCsrData({});
    setCheckStatusData({});
    setCheckServicesData({});
    setCheckTechnicianData({});
    setCheckStationCode({});
    setCheckDspData({});
    setCount(0);
  };
  const toggleDrawer = (newOpen: boolean, outside?: any) => () => {
    setOpen(newOpen);
    if (
      !(
        urlParams.get('vendor_ids') ||
        urlParams.get('technician_ids') ||
        urlParams.get('csr_ids') ||
        urlParams.get('invoice_status') ||
        urlParams.get('services') ||
        urlParams.get('partner_ids') ||
        urlParams.get('station_ids') ||
        urlParams.get('dsp_ids') ||
        urlParams.get('partner_ids')
      )
    ) {
      setCheckData({});
      setCheckCsrData({});
      setCheckStatusData({});
      setCheckServicesData({});
      setCheckTechnicianData({});
      setCheckStationCode({});
      setCheckDspData({});
      setCount(0);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckData({ ...checkData, [event.target.name]: event.target.checked });
  };
  const handleCsrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckCsrData({ ...checkCsrData, [event.target.name]: event.target.checked });
  };
  const handleChangePartnerData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPartnerData({ ...checkPartnerData, [event.target.name]: event.target.checked });
  };
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckStatusData({ ...checkStatusData, [event.target.name]: event.target.checked });
  };
  const handleServicesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckServicesData({ ...checkServicesData, [event.target.name]: event.target.checked });
  };
  const handleTechnicianChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckTechnicianData({ ...checkTechnicianData, [event.target.name]: event.target.checked });
  };
  const handleDspChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckDspData({ ...checkDspData, [event.target.name]: event.target.checked });
  };
  const handleStationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckStationCode({ ...checkStationCode, [event.target.name]: event.target.checked });
  };
  function capitalizeFirstLetter(sentence: any) {
    let words = sentence?.split(' ') || [];
    let capitalizedWords = words.map((word: any) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    let capitalizedSentence = capitalizedWords.join(' ');
    return capitalizedSentence;
  }
  return (
    <div>
      <Tooltip
        title={
          !(
            invoiceView?.status ||
            invoiceView?.csr ||
            invoiceView?.vendor ||
            invoiceView?.technician ||
            invoiceView?.dsp_name ||
            invoiceView?.service_items ||
            invoiceView.station_code ||
            invoiceView?.partner_id
          ) && 'Filter Not Available On These Fields'
        }>
        <button
          className={`flex justify-start items-center rounded-full h-[2.6rem] border-[0.15rem] border-gray px-[0.5rem] ${
            count > 0 ? 'w-[10rem]' : 'w-[9rem]'
          }  ml-[7rem] bg-primary-lighter `}
          onClick={toggleDrawer(true)}
          disabled={
            !(
              invoiceView?.status ||
              invoiceView?.csr ||
              invoiceView?.vendor ||
              invoiceView?.technician ||
              invoiceView?.dsp_name ||
              invoiceView?.service_items ||
              invoiceView.station_code ||
              invoiceView?.partner ||
              invoiceView?.partner_id
            )
          }>
          <div>
            <img
              src='../../svg/filter.svg'
              alt='DownArrowBtn'
              className='inline-block h-[1.1rem] w-[1.1rem] ml-[0.5rem]  pb-[0.1rem]'
            />
          </div>
          <div
            className={`whitespace-norwrap ${
              !(
                invoiceView?.status ||
                invoiceView?.csr ||
                invoiceView?.vendor ||
                invoiceView?.technician ||
                invoiceView?.dsp_name ||
                invoiceView?.service_items ||
                invoiceView.station_code ||
                invoiceView?.partner_id
              )
                ? 'text-gray-400'
                : 'text-gray-600'
            } font-bold-700 text-[1.1rem] pl-[1rem] `}>
            All Filters
          </div>
          {count > 0 && (
            <div
              style={{
                backgroundColor: '#CC5555',
                color: 'white',
                boxShadow: '0px 1.26667px 2.53333px rgba(0, 0, 0, 0.25)',
                width: count > 98 ? '1.8rem' : '1.3rem',
                height: '1.2rem',
                padding: '0 0.3rem',
                marginLeft: '0.2rem',
              }}
              className='rounded-full text-[0.8rem]'>
              {count > 98 ? '99+' : count}
            </div>
          )}
        </button>
      </Tooltip>

      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false, true)}>
        <div className='px-[1rem] text-gray-400 font-bold text-[1.1rem] p-[0.5rem]'>
          All Filters
        </div>
        <div className='flex flex-col justify-between h-[100%] w-[25rem]'>
          <div>
            {/* Status */}
            {invoiceView?.status && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenStatus(!isOpenStatus);
                  }}>
                  <div>Status</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenStatus ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenStatus && (
                  <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {getStatus(user?.role).map((item: any) => (
                      <label
                        key={item?.userStatus}
                        className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                        <input
                          type='checkbox'
                          name={item?.userStatus}
                          checked={checkStatusData?.[item?.userStatus] || false}
                          onChange={handleStatusChange}
                          className='mr-2'
                        />
                        <Tootltip
                          followCursor
                          title={`${capitalizeFirstLetter(getStatusName(item?.userStatus))}`}
                          placement={'bottom'}>
                          <p
                            className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                            {capitalizeFirstLetter(getStatusName(item?.userStatus))}
                          </p>
                        </Tootltip>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            )}
            {/* end  */}
            {invoiceView?.csr && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenCsr(!isOpenCsr);
                  }}>
                  <div>CSR Name</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenCsr ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenCsr && (
                  <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {allCsrData?.length > 0 ? (
                      allCsrData?.map((item: any) => (
                        <label
                          key={item?.user_name}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkCsrData?.[item?.id] || false}
                            onChange={handleCsrChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_name)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.user_name)}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {invoiceView?.partner && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenPartner(!isOpenPartner);
                  }}>
                  <div>Partner Name</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenPartner ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenPartner && (
                  <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {allPartnerData?.length > 0 ? (
                      allPartnerData?.map((item: any) => (
                        <label
                          key={item?.user_name}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkPartnerData?.[item?.id] || false}
                            onChange={handleChangePartnerData}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_name)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.user_name)}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {user.role !== 'fleetmax_vendor' && invoiceView?.vendor && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenVendor(!isOpenVendor);
                  }}>
                  <div>Vendor Name</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenVendor ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenVendor && (
                  <div className='pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {allVendorData?.length > 0 ? (
                      allVendorData?.map((item: any) => (
                        <label
                          key={item?.user_name}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkData?.[item?.id] || false}
                            onChange={handleChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_name)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(
                                item?.user_name === user?.name
                                  ? `${item?.user_name} (Me)`
                                  : item?.user_name
                              )}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {invoiceView?.technician && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem] text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenTechnician(!isOpenTechnician);
                  }}>
                  <div>Technician Name</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenTechnician ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenTechnician && (
                  <div className='pt-2 mx-4 max-h-[8rem] overflow-y-auto  scrollbar-medium'>
                    {allTechnicianData?.length > 0 ? (
                      allTechnicianData?.map((item: any) => (
                        <label
                          key={item?.user_name}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkTechnicianData?.[item?.id] || false}
                            onChange={handleTechnicianChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_name)} ${
                              item?.vendor_name && user?.role === 'fleetmax_csr'
                                ? `${capitalizeFirstLetter(item?.vendor_name)}`
                                : ''
                            }`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[20rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.user_name)}
                              <>
                                {item?.vendor_name && user?.role === 'fleetmax_csr' && (
                                  <> ({capitalizeFirstLetter(item?.vendor_name)})</>
                                )}
                              </>
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}{' '}
            {/* /Dsp user/ */}
            {invoiceView?.dsp_name && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem] text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenDsp(!isOpenDsp);
                  }}>
                  <div>DSP Name</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenDsp ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenDsp && (
                  <div className='pt-2 mx-4 max-h-[8rem] overflow-y-auto  scrollbar-medium'>
                    {allDspData?.length > 0 ? (
                      allDspData?.map((item: any) => (
                        <label
                          key={item?.user_name}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkDspData?.[item?.id] || false}
                            onChange={handleDspChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_name)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.user_name)}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {/* services  */}
            {invoiceView?.service_items && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenServices(!isOpenServices);
                  }}>
                  <div>Services</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenServices ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenServices && (
                  <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {allServicesData?.length > 0 ? (
                      allServicesData?.map((item: any) => (
                        <label
                          key={item?.id}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.id}
                            checked={checkServicesData?.[item?.id] || false}
                            onChange={handleServicesChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.name)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[20rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.name)}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}{' '}
            {/* Station code */}
            {invoiceView.station_code && (
              <div>
                <div
                  className='flex justify-between px-[1rem] pt-[0.3rem]  text-[1.2rem] font-bold pb-[0.3rem] border-b-[0.15rem] cursor-pointer'
                  onClick={() => {
                    setIsOpenStationCode(!isOpenStationCode);
                  }}>
                  <div>Station Codes</div>
                  <i
                    className={`pi pi-angle-up ${
                      isOpenStationCode ? 'rotate-0' : 'rotate-180'
                    } ease-in-out duration-200 text-[1.2rem] pt-[0.3rem] font-bold`}
                  />
                </div>
                {isOpenStationCode && (
                  <div className=' pt-2 mx-4 max-h-[8rem] overflow-y-auto scrollbar-medium'>
                    {allStationCodeData?.length > 0 ? (
                      allStationCodeData?.map((item: any) => (
                        <label
                          key={item?.user_dsp_code}
                          className='flex items-center mb-2 text-[1.1rem] wrap cursor-pointer'>
                          <input
                            type='checkbox'
                            name={item?.user_company_id}
                            checked={checkStationCode?.[item?.user_company_id] || false}
                            onChange={handleStationCodeChange}
                            className='mr-2'
                          />
                          <Tootltip
                            followCursor
                            title={`${capitalizeFirstLetter(item?.user_dsp_code)}`}
                            placement={'bottom'}>
                            <p
                              className={`${'max-w-[12rem]'} text-ellipsis whitespace-nowrap overflow-hidden `}>
                              {capitalizeFirstLetter(item?.user_dsp_code)}
                            </p>
                          </Tootltip>
                        </label>
                      ))
                    ) : (
                      <p className={`text-center`}>
                        <img
                          src='../../svg/info.svg'
                          alt='infoIcon'
                          className='inline-block h-[1.5rem] w-[1.7rem] pr-[0.5rem] pb-[0.1rem]'
                        />
                        No Data Found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='w-full px-4 py-2 flex gap-2 justify-between mt-3'>
            <Button
              disabled={count === 0}
              onClick={() => {
                handleCancelFilter();
                setCurrentPage(1);
                urlParams.set('page_number', '1');
                setSearchParams(urlParams);
              }}
              className={`px-4 py-2 w-[40%]  rounded-lg font-bold whitespace-nowrap hover:bg-primary-light ${
                count === 0 ? 'text-gray-400' : 'text-primary'
              }`}>
              Clear All
            </Button>
            <Button
              onClick={handleApplyFilter}
              className='px-4 py-2 w-[40%] bg-primary text-white rounded-lg font-bold whitespace-nowrap'>
              Apply Filter
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
