import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Notification from '../../../pages/notification';
import { RootState } from '../../../state/reducers';
import MainContent from '../main-content/MainContent';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../state';
import { toast } from '../../Toast/CustomToast';
import { useNavigate } from 'react-router';
import BulkCalendarSvg from '../../../assets/BulkCalender';
import { makeBulkScheduleData } from '../../../pages/vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import useFetchSingleRequest from '../../../pages/hooks/csr/useFetchSingleRequest';
import { useEffect, useState } from 'react';
import PartnerApi from '../../../api/partnerSpecificApi';
import CustomiseInvoices from '../layout-vendor/components/CustomiseInvoices';

const iconSize = 16; // Size in pixels
const iconSizeRem = `${iconSize / 16}rem`; // Convert size to rem for scalability

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '3px',
  width: iconSizeRem,
  height: iconSizeRem,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgba(16,22,26,.4)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#ffffff',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: '2px',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: iconSizeRem,
    height: iconSizeRem,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

interface DataRow {
  date: string;
  invoice_id?: string;
  request_id: string;
  station_code?: string;
  dsp_shortcode?: string;
  van_name?: string;
  van_type?: string;
  vin_number?: string;
  csr?: string;
  vendor?: string;
  technician: string;
  amount: string;
  service_items?: string;
  status: string;
  dsp_name?: string;
  sub_request_status?: string;
  miles?: string;
  current_miles?: string;
  miles_till_inspection?: string;
  service_completion_date?: string;
  request_substatus?: string;
}

interface DownloadViewItem {
  id: number;
  name: string;
  selected: boolean;
}
interface DownloadViewObject {
  [key: string]: boolean;
}
export default function LayoutPartner() {
  const location = useLocation();
  const { layoutContents }: any = useSelector((state: RootState) => state.userState);
  let pathname = location.pathname;
  const isInvoicePage = window.location.href.includes('invoice');
  const isVendorRequestReport = window.location.href.includes('/vendorRequestReport');
  const isVehiclePage = window.location.href.includes('vehicle');
  const { multiSelectReq, acceptedDataVendor, customizeView }: any = useSelector(
    (state: RootState) => state.userState
  );
  const [urlParams, setSearchParams]: any = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setMultReq, setBulkVehicles, setInvoiceView, setDownloadView, setCustomizeView } =
    bindActionCreators(actionCreators, dispatch);
  const { getFullRequestInfo } = useFetchSingleRequest();
  const handleAssignVendorMultiSelect = async () => {
    if (Array.isArray(multiSelectReq?.data)) {
      const pmIds = multiSelectReq?.data?.map((d: any) => d.id);
      const query = `preventive_request_id_arr=[${pmIds}]`;
      const data = await getFullRequestInfo(query);
      setBulkVehicles({
        bulkVehicles: await makeBulkScheduleData(data),
      });
      navigate('/scheduleRequest');
    }
  };
  const isVehicleAcceptedPage =
    urlParams.get('type') === 'accepted' && isVehiclePage ? true : false;
  const fields: { name: string; key: keyof DataRow }[] = [
    { name: 'Date', key: 'date' },
    { name: 'Request Id', key: 'request_id' },
    { name: 'Station code', key: 'station_code' },
    { name: 'DSP Shortcode', key: 'dsp_shortcode' },
    { name: 'DSP Name', key: 'dsp_name' },
    { name: 'Van Name', key: 'van_name' },
    { name: 'Van Type', key: 'van_type' },
    { name: 'VIN Number', key: 'vin_number' },
    { name: 'CSR', key: 'csr' },
    { name: 'Vendor', key: 'vendor' },
    { name: 'Technician', key: 'technician' },
    { name: 'Service Items', key: 'service_items' },
    { name: 'Status', key: 'status' },
    ...(window.location.href.includes('/vendorRequestReport')
      ? [
          { name: 'Request Substatus', key: 'request_substatus' as keyof DataRow },
          { name: 'Last Preventive Maintaince', key: 'miles' as keyof DataRow },
          { name: 'Current Miles', key: 'current_miles' as keyof DataRow },
          { name: 'Miles Till Inspection', key: 'miles_till_inspection' as keyof DataRow },
          { name: 'Service Completion Date', key: 'service_completion_date' as keyof DataRow },
        ]
      : [
          { name: 'Invoice Id', key: 'invoice_id' as keyof DataRow },
          { name: 'Amount', key: 'amount' as keyof DataRow },
        ]),
  ];

  //invoice management
  const [selectedInvoiceFields, setSelectedInvoiceFields] = useState<any>({});
  const [selectedDownloadFields, setSelectedDownloadFields] = useState<any>({});
  const [initialLayout, setInitialLayout] = useState<any>({ view: {}, download: {} });

  const [filterType, setFilterType] = useState<'invoice' | 'download'>('invoice');
  const [invoiceAllChecked, setInvoiceAllChecked] = useState(false);
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType((event.target as HTMLInputElement).value as 'invoice' | 'download');
  };
  const convertArrayToObject = (downloadView: DownloadViewItem[]): { [key: string]: boolean } => {
    const result: { [key: string]: boolean } = {};
    downloadView?.forEach((item) => {
      if (item?.name !== 'Sub Request Status') {
        const key = item.name.toLowerCase().replace(/\s+/g, '_');
        result[key] = item.selected;
      }
    });
    return result;
  };
  useEffect(() => {
    PartnerApi.getAllInvoiceView({
      ...(isVendorRequestReport && { params: '?request_report=true' }),
    }).then((res: any) => {
      if (res.status === 200) {
        const resultData: any = res.data.data;
        const finalInvoiceData: any = convertArrayToObject(
          resultData?.invoice_view || resultData?.request_view
        );
        const finalDownloadData: any = convertArrayToObject(
          resultData?.download_view || resultData?.downloadable_fields
        );
        setSelectedInvoiceFields(finalInvoiceData);
        setSelectedDownloadFields(finalDownloadData);
        setInvoiceView(finalInvoiceData);
        setDownloadView(finalDownloadData);
        setInitialLayout({
          view: finalInvoiceData,
          download: finalDownloadData,
        });
      } else {
        toast.error('Something Went wrong');
      }
    });
    setInvoiceAllChecked(false);
  }, [customizeView, isVendorRequestReport]);

  useEffect(() => {
    if (isInvoicePage || isVendorRequestReport) {
      setCustomizeView(false);
    }
  }, [isInvoicePage, isVendorRequestReport]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedInvoiceFields({
      ...selectedInvoiceFields,
      [event.target.name]: event.target.checked,
    });
  };
  const handleDownloadCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDownloadFields({
      ...selectedDownloadFields,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOnClose = () => {
    setInvoiceAllChecked(false);
    setCustomizeView(false);
    setFilterType('invoice');
    if (initialLayout?.view || initialLayout?.download) {
      setSelectedInvoiceFields(initialLayout?.view);
      setSelectedDownloadFields(initialLayout?.download);
      setInvoiceView(initialLayout?.view);
      setDownloadView(initialLayout?.download);
    }
  };
  const convertObjectToArray = (
    obj: DownloadViewObject
  ): { id: number; name: string; selected: boolean }[] => {
    const downloadView: string[] = [
      'Date',
      'Request ID',
      'Station Code',
      'DSP Shortcode',
      'DSP Name',
      'Van Type',
      'VIN Number',
      'Van Name',
      'CSR',
      'Vendor',
      'Technician',
      'Service Items',
      'Status',
      ...(window.location.href.includes('/vendorRequestReport')
        ? [
            'Request Substatus',
            'Miles',
            'Current Miles',
            'Miles Till Inspection',
            'Service Completion Date',
          ]
        : ['Invoice ID', 'Amount']),
    ];

    return downloadView.map((name: string, index: number) => ({
      id: index + 1,
      name,
      selected: obj[name.toLowerCase().replace(/\s+/g, '_')] || false,
    }));
  };
  const handleSaveLayout = () => {
    const commonData = isVendorRequestReport
      ? {
          request_view: convertObjectToArray(selectedInvoiceFields),
          downloadable_fields: convertObjectToArray(selectedDownloadFields),
        }
      : {
          invoice_view: convertObjectToArray(selectedInvoiceFields),
          download_view: convertObjectToArray(selectedDownloadFields),
        };
    const resultData: any = {
      ...commonData,
      ...(isVendorRequestReport && { request_report: true }),
    };
    PartnerApi.setInvoiceView({
      data: resultData,
    }).then((res: any) => {
      if (res.status === 200) {
        toast.success('Changes saved successfully ');
        PartnerApi.getAllInvoiceView({
          ...(isVendorRequestReport && { params: '?request_report=true' }),
        }).then((res: any) => {
          if (res.status === 200) {
            const resultData: any = res.data.data;
            const finalInvoiceData: any = convertArrayToObject(
              resultData?.invoice_view || resultData?.request_view
            );
            const finalDownloadData: any = convertArrayToObject(
              resultData?.download_view || resultData?.downloadable_fields
            );
            setSelectedInvoiceFields(finalInvoiceData);
            setSelectedDownloadFields(finalDownloadData);
            setInvoiceView(finalInvoiceData);
            setDownloadView(finalDownloadData);
            setInitialLayout({
              view: finalInvoiceData,
              download: finalDownloadData,
            });
            setInvoiceAllChecked(false);
          }
        });
      }
    });
  };

  function countTrueValues(obj: any): number {
    let count = 0;
    for (let key in obj) {
      if (obj[key] === true) {
        count++;
      }
    }
    return count;
  }
  useEffect(() => {
    if (invoiceAllChecked) {
      setSelectedDownloadFields(selectedInvoiceFields);
    }
  }, [invoiceAllChecked, selectedInvoiceFields]);

  return (
    <div
      className='flex w-full h-screen overflow-hidden pb-5  flex-col border-b-[0.1rem] border-b-gray-300'
      style={
        pathname === '/vendor/profile' || pathname === '/vendor/notifications'
          ? {
              background: 'linear-gradient(0deg, #E0F9D7 17.45%, #F3FFFD 57.77%, #E7FFFE 100%)',
            }
          : {}
      }>
      <div className=' border-b-[0.1rem] border-b-gray-300'>
        <div className='w-full flex justify-between h-16'>
          <div className='h-full flex items-center text-[1.5rem] font-bold pl-5'>
            {(pathname === '/partner/dashboard' || pathname === '/partner/dashboard/revenue') && (
              <>Dashboard</>
            )}
            {pathname === '/partner/vehicle' && <>Vehicles</>}
            {pathname === '/partner/chat' && 'Chat'}
            {(pathname === '/partner/invoice' || pathname === '/partner/vendorRequestReport') && (
              <CustomiseInvoices />
            )}
          </div>
          <div
            className={`flex-1 rounded-xl items-center flex gap-10 px-4 justify-end 
          }`}>
            {isVehicleAcceptedPage && Number(acceptedDataVendor) > 1 && (
              <div className='text-[1.2rem] text-primary font-bold py-[1rem] flex'>
                <div className='p-2 flex gap-2'>
                  <div
                    className='flex items-center gap-2 cursor-pointer px-4'
                    onClick={() => {
                      if (!urlParams.get('companies')) {
                        toast.error('Please select a station code');
                        return;
                      }
                      if (multiSelectReq.show) {
                        if (multiSelectReq?.data?.length < 2) {
                          toast.error('Please select atleast 2 requests');
                          return;
                        }
                      }
                      if (
                        multiSelectReq &&
                        Array.isArray(multiSelectReq?.data) &&
                        multiSelectReq.data?.length > 1
                      ) {
                        handleAssignVendorMultiSelect();
                      } else {
                        setMultReq({
                          show: multiSelectReq.show ? false : true,
                          data: [],
                        });
                        if (!multiSelectReq.show) {
                          urlParams.set('only_single', true);
                          setSearchParams(urlParams);
                          setTimeout(() => {
                            setMultReq({
                              show: multiSelectReq.show ? false : true,
                              data: [],
                            });
                          }, 800);
                        }
                      }
                    }}>
                    {multiSelectReq.show ? (
                      <div className='flex justify-between items-center gap-4'>
                        <div className='bg-primary font-bold rounded-[1rem] shadow-md w-[2.5rem] h-[2.5rem] flex justify-center items-center'>
                          <i className='pi pi-plus-circle text-white text-[1.5rem]'></i>
                        </div>
                        <p className='flex flex-col'>
                          <p className='font-bold text-[1.1rem]'>Bulk Assign</p>
                          <p className='text-[0.8rem] font-normal'>
                            {multiSelectReq?.data?.length} Selected
                          </p>
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className='hover:bg-primary-light py-[0.2rem] px-[0.5rem] rounded-full flex '>
                          <div className='pt-[0.4rem] pr-[0.3rem]'>
                            <BulkCalendarSvg />
                          </div>
                          Bulk Action
                        </div>
                      </>
                    )}
                  </div>
                  {multiSelectReq.show && (
                    <div>
                      <Button
                        variant={'contained'}
                        onClick={() => {
                          setMultReq({
                            show: false,
                            data: [],
                          });
                          urlParams.delete('only_single');
                          setSearchParams(urlParams);
                        }}
                        color={'error'}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {layoutContents?.search &&
              (isInvoicePage || isVehiclePage || isVendorRequestReport) &&
              layoutContents?.search}
            <div className='flex items-center justify-center gap-4 h-[90%]'>
              <Notification />
            </div>
          </div>
        </div>
        {customizeView && (isInvoicePage || isVendorRequestReport) && (
          <div className='w-full h-[11rem]'>
            <div className='flex pl-5 justify-between pb-[0.4rem]'>
              <div className='flex gap-2 items-center'>
                <RadioGroup
                  row
                  aria-label='filterType'
                  name='filterType'
                  value={filterType}
                  onChange={handleFilterChange}>
                  <FormControlLabel
                    value='invoice'
                    control={
                      <Radio
                        sx={{
                          padding: '0.3rem',
                          paddingLeft: '0.7rem',
                          '& .MuiSvgIcon-root': {
                            fontSize: '1.2rem', // Adjust the size as needed
                          },
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: '1.1rem' }}>
                        {isVendorRequestReport ? 'Request' : 'Invoice'} View
                      </span>
                    }
                  />
                  <FormControlLabel
                    value='download'
                    control={
                      <Radio
                        sx={{
                          padding: '0.3rem',
                          paddingLeft: '0.7rem',
                          '& .MuiSvgIcon-root': {
                            fontSize: '1.2rem', // Adjust the size as needed
                          },
                        }}
                      />
                    }
                    label={<span style={{ fontSize: '1.1rem' }}>Downloadable Fields</span>}
                  />
                </RadioGroup>
                {(countTrueValues(selectedInvoiceFields) < 2 ||
                  countTrueValues(selectedInvoiceFields) > 10) &&
                  filterType === 'invoice' && (
                    <div className='flex items-center justify-center h-[2.1rem] ml-[2rem] text-red-500'>
                      <div>
                        <img
                          src='../../svg/error.svg'
                          alt='error'
                          className='inline-block h-[1.1rem] w-[1.1rem] mr-[0.5rem]  mb-[0.2rem]'
                        />
                      </div>
                      {countTrueValues(selectedInvoiceFields) > 10 ? (
                        <div>Maximum selection limit exceeded</div>
                      ) : (
                        <div>Minimum 2 selections are necessary</div>
                      )}
                    </div>
                  )}
              </div>

              <div className='flex'>
                {filterType === 'invoice' && (
                  <FormControlLabel
                    key={'1'}
                    control={
                      <Checkbox
                        checked={invoiceAllChecked}
                        onChange={(e) => {
                          setInvoiceAllChecked(e.target.checked);
                        }}
                        disableRipple
                        color='default'
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        className='min-w-[2rem] min-h-[0.8rem]'
                      />
                    }
                    label={
                      <span style={{ fontSize: '1.1rem' }}>Keep same in Downloadable fields</span>
                    }
                  />
                )}
                <div>
                  <Button
                    disabled={
                      countTrueValues(selectedInvoiceFields) < 2 ||
                      countTrueValues(selectedInvoiceFields) > 10
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleSaveLayout();
                    }}
                    variant='text'
                    className='font-bold text-[1rem] hover:bg-primary-light py-[0.3rem] text-center'>
                    Save Layout
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={handleOnClose}
                    variant='text'
                    className='font-bold text-red-500 text-[1rem] mr-[1rem] hover:bg-red-100 py-[0.3rem] text-center'>
                    Close
                  </Button>
                </div>
              </div>
            </div>
            <div className='flex max-w-[98%] flex-wrap ml-[0.3rem]'>
              {fields.map((field) => {
                return (
                  <div
                    className='border-[0.1rem] border-borderColor min-w-[2rem] min-h-[2rem] mx-[0.5rem] mb-[1rem] rounded-full '
                    key={field?.key?.toString()}>
                    <div className='flex items-center justify-center pl-[1.2rem] shadow-md rounded-full min-w-[2rem] min-h-[2rem]'>
                      <FormControlLabel
                        key={field.key.toString()}
                        control={
                          <Checkbox
                            checked={
                              filterType === 'invoice'
                                ? selectedInvoiceFields[field.key] || false
                                : selectedDownloadFields[field.key] || false
                            }
                            onChange={
                              filterType === 'invoice'
                                ? handleCheckboxChange || false
                                : handleDownloadCheckboxChange || false
                            }
                            name={field.key.toString()}
                            disableRipple
                            color='default'
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            className='min-w-[2rem] min-h-[0.5rem]'
                          />
                        }
                        label={
                          <span style={{ fontSize: '1rem' }}>
                            {field.name === 'Last Preventive Maintaince' ? 'Last PM' : field.name}
                          </span>
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <MainContent>
        <Outlet />
      </MainContent>
    </div>
  );
}
