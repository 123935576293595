import { formatStatus } from './InvoiceStatus';

function getStatus(userRole: string) {
  const isVendorRequestReport: boolean = window.location.href.includes('vendorRequestReport');

  if (userRole === 'fleetmax_csr') {
    return [
      { userStatus: 'scheduled' },
      { userStatus: 'in_progress' },
      { userStatus: 'disputed' },
      { userStatus: 'request_completed' },
      { userStatus: 'payment_confirmed' },
      { userStatus: 'rejected_by_csr' },
    ];
  } else if (isVendorRequestReport) {
    if (userRole === 'fleetmax_vendor') {
      return [
        { userStatus: 'pending_vendor' },
        { userStatus: 'accepted_vendor' },
        { userStatus: 'assigned_vendor' },
        { userStatus: 'approved_vendor' },
        { userStatus: 'rejected_by_vendor' },
        { userStatus: 'disputed' },
        { userStatus: 'payment_confirmed' },
      ];
    } else
      return [
        { userStatus: 'pending_partner' },
        { userStatus: 'rejected_partner' },
        { userStatus: 'accepted_partner' },
        { userStatus: 'assigned_partner' },
        { userStatus: 'approved_partner' },
        { userStatus: 'disputed' },
        { userStatus: 'payment_confirmed' },
      ];
  } else
    return [
      { userStatus: 'received' },
      { userStatus: 'approved' },
      { userStatus: 'disputed' },
      { userStatus: 'payment_received' },
    ];
}

export default getStatus;

export function getStatusName(status: string) {
  switch (status) {
    case 'scheduled':
      return 'Scheduled';
    case 'payment_received':
      return 'Payment Received';
    case 'in_progress':
      return 'In Progress';
    case 'payment_completed':
      return 'Request Completed';
    case 'payment_confirmed':
      return 'Payment Confirmed';
    case 'rejected_by_csr':
      return 'Rejected';
    case 'pending_vendor':
      return 'Requests (Pending)';
    case 'accepted_vendor':
      return 'Accepted';
    case 'assigned_vendor':
      return 'Assigned';
    case 'approved_vendor':
      return 'Approved';
    case 'rejected_by_vendor':
      return 'Rejected';
    case 'rejected_partner':
      return 'Rejected';
    case 'accepted_partner':
      return 'Accepted';
    case 'assigned_partner':
      return 'Assigned';
    case 'approved_partner':
      return 'Approved';
    case 'pending_partner':
      return 'Requests (Pending)';
    case 'assigned':
      return 'Assigned';
    case 'rejected':
      return 'Rejected';
    case 'accepted':
      return 'Accepted';
    case 'requests':
      return 'Requests';
    case 'dispute':
      return 'Disputed';
    case 'disputed':
      return 'Disputed';
    case 'payment_confirmed_vendor':
      return 'Payment Confirmed by Vendor';
    case 'payment_confirmed_partner':
      return 'Payment Confirmed by Partner';
    case 'received':
      return 'Received';
    case 'approved':
      return 'Approved';
    case 'disputed_general':
      return 'Disputed';
    case 'pending':
      return 'Pending';
    case 'invoice_sent':
      return 'Invoice Sent';
    case 'unknown':
      return 'N/A';
    default:
      return formatStatus(String(status));
  }
}
