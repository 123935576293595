import { Tooltip } from '@mui/material';
import { GetVehicleName } from './GetCsrNotification';
import { toast } from '../../components/Toast/CustomToast';

const getBtnName = (item: any) => {
  if (item.status_id === '-1' && item.sub_stage === '1') {
    return 'View In Requests';
  }

  if (item.status_id === '-1' && item.sub_stage === '2') {
    return 'View In Accepted';
  }

  if (
    ['5', '6', '7', '8'].includes(item.status_id) ||
    (item.status_id === '4' && (item.sub_stage === '1' || item.sub_stage === '2'))
  ) {
    return 'View In Assigned';
  }
  if (['9', '11'].includes(item.status_id) || (item?.status_id === '12' && !item?.sub_stage)) {
    return 'View In Approved';
  }
  if (['10'].includes(item.status_id)) {
    return 'View In Rejected';
  }
  if (item?.status_id === '12' && item?.sub_stage === '1') {
    return 'View In Payment Confirmed';
  }
};

export const getInfoPARTNER = (notification: any, notficationActions: any) => {
  const info = JSON.parse(notification?.message);
  const actionType: string = info?.action;
  const tempNotfication = {
    ...notification,
    messageInfo: info,
  };

  if (['invoice send', 'invoice_send'].includes(actionType)) {
    return {
      ...tempNotfication,
      notificationMsg: (
        <p className='text-start'>
          <Tooltip title={'CSR'}>
            <span className='capitalize'>CSR: {info.name} </span>
          </Tooltip>
          has sent invoice for <GetVehicleName info={info} />
        </p>
      ),
      btnName: getBtnName(notification),
      onClick: (e: any) => {
        notficationActions['RedirectToRequest'](e, notification);
      },
    };
  }

  if (['work_proof_submitted', 'work_proof_submitted_technician'].includes(actionType)) {
    return {
      ...tempNotfication,
      notificationMsg: (
        <p className='text-start'>
          <Tooltip title={actionType === 'work_proof_submitted' ? 'CSR' : 'Technician'}>
            <span className='capitalize flex'>
              {actionType === 'work_proof_submitted' ? <>CSR: </> : <>Technician: </>} {info.name}
            </span>
          </Tooltip>
          has submitted work proof for <GetVehicleName info={info} />
        </p>
      ),
      btnName: getBtnName(notification),
      onClick: (e: any) => {
        notficationActions['RedirectToRequest'](e, notification);
      },
    };
  }

  if (['workproof accept', 'workproof_accept'].includes(actionType)) {
    return {
      ...tempNotfication,
      notificationMsg: (
        <p className='text-start'>
          <Tooltip title={'Technician'}>
            <span className='capitalize'>Technician: {info.name} </span>
          </Tooltip>
          has accepted work proof for <GetVehicleName info={info} />
        </p>
      ),
      btnName: getBtnName(notification),
      onClick: (e: any) => {
        notficationActions['RedirectToRequest'](e, notification);
      },
    };
  }

  if (['workproof reject', 'workproof_reject'].includes(actionType)) {
    return {
      ...tempNotfication,
      notificationMsg: (
        <p className='text-start'>
          <Tooltip title={'CSR'}>
            <span className='capitalize'>CSR: {info.name} </span>
          </Tooltip>
          has rejected <span className=' '>Workproof </span> for <GetVehicleName info={info} />
        </p>
      ),
      btnName: getBtnName(notification),
      onClick: (e: any) => {
        notficationActions['RedirectToRequest'](e, notification);
      },
    };
  }

  switch (actionType) {
    case 'assign':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'CSR'}>
              <span className='capitalize'>CSR: {info.name} </span>
            </Tooltip>
            has assigned a new{' '}
            {tempNotfication?.request_type === 'repair'
              ? 'repair'
              : `${!tempNotfication?.bulk_id ? 'maintenance' : ''}`}{' '}
            request for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (notification?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    case 'technician_assign':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'CSR'}>
              <span className='capitalize'>
                {tempNotfication?.assigned_by_type === '11' ? 'CSR' : 'Partner'}: {info.name}{' '}
              </span>
            </Tooltip>
            has assigned a new{' '}
            {tempNotfication?.request_type === 'repair'
              ? 'repair'
              : `${!tempNotfication?.bulk_id ? 'maintenance' : ''}`}{' '}
            request to technician for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (notification?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    case 'working_by_technician':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Technician'}>
              <span className='capitalize'>Technician: {info.name} </span>
            </Tooltip>
            has started working for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };
    case 'workproof due':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Work proof due for <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'work_proof_skipped_technician':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            <Tooltip title={'Technician'}>
              <span className='capitalize'>Technician: {info.name} </span>
            </Tooltip>{' '}
            {' has skipped work proof for'}
            <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['RedirectToRequest'](e, notification);
        },
      };

    case 'approval_pending':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Please take action for the{' '}
            {tempNotfication.bulk_id
              ? `Bulk Request id - ${tempNotfication?.bulk_id}`
              : `Request id - ${tempNotfication?.pm_request_id}`}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (tempNotfication.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };

    case 'technician_started_journey':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician: <span className='capitalize'>{info.name}</span> has started journey for the
            maintenence of <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['readNotification'](e, notification);
          toast.info(
            "To access the technician's live location, please use the Vendor Mobile application"
          );
        },
      };
    case 'technician_reached_destination':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Technician: <span className='capitalize'>{info.name}</span> has reached to the
            destination for the maintenence of <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          notficationActions['readNotification'](e, notification);
          toast.info(
            "To access the technician's live location, please use the Vendor Mobile application"
          );
        },
      };

    // case 'edited_request':
    //   return {
    //     ...tempNotfication,
    //     notificationMsg: (
    //       <p className='text-start'>
    //         {tempNotfication.bulk_id
    //           ? `Bulk Request id - ${tempNotfication?.bulk_id}`
    //           : `Request id - ${tempNotfication?.pm_request_id}`}{' '}
    //         has been updated by CSR {info?.name}
    //       </p>
    //     ),
    //     btnName: getBtnName(notification),
    //     onClick: (e: any) => {
    //       if (notification?.bulk_id) {
    //         notficationActions['handleBulkRedirect'](e, notification);
    //       } else {
    //         notficationActions['RedirectToRequest'](e, notification);
    //       }
    //     },
      // };
    case 'updated_invoice':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            The invoice has been updated for the <GetVehicleName info={info} />
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (notification?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    case 'payment_processed':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Payment processed for the request{' '}
            {tempNotfication.bulk_id
              ? `Bulk Request id - ${tempNotfication?.bulk_id}`
              : `Request id - ${tempNotfication?.pm_request_id}`}
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (notification?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
    case 'request_cancelled':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {tempNotfication.bulk_id
              ? `Bulk Request ${tempNotfication?.bulk_id}`
              : `Request ${tempNotfication?.pm_request_id}`}{' '}
            has been canceled by the{' '}
            <Tooltip title={'CSR'}>
              <span className='capitalize'>CSR: {info.name} </span>
            </Tooltip>
            .
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: () => {},
      };
    case 'request_cancelled':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {tempNotfication.bulk_id
              ? `Bulk Request ${tempNotfication?.bulk_id}`
              : `Request ${tempNotfication?.pm_request_id}`}{' '}
            has been canceled by the{' '}
            <Tooltip title={'CSR'}>
              <span className='capitalize'>CSR: {info.name} </span>
            </Tooltip>
            .
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: () => {},
      };
    case 'schedule_appointment_to_partner':
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            Request sheduled to you by the{' '}
            <Tooltip title={'CSR'}>
              <span className='capitalize'>CSR: {info.name} </span>
            </Tooltip>
            for <GetVehicleName info={info} />.
          </p>
        ),
        btnName: getBtnName(notification),
        onClick: () => {},
      };
    default:
      return {
        ...tempNotfication,
        notificationMsg: (
          <p className='text-start'>
            {info?.message_name} <span className='capitalize'>{info?.name}</span> for{' '}
            <GetVehicleName info={info} />
          </p>
        ),
        // btnName: getBtnName(notification),
        onClick: (e: any) => {
          if (notification?.bulk_id) {
            notficationActions['handleBulkRedirect'](e, notification);
          } else {
            notficationActions['RedirectToRequest'](e, notification);
          }
        },
      };
  }
};
